import "./App.css";

function App() {
  return (
    <div className="main">
      <h2>Coming Soon</h2>
      <p>Unlock the future with <b>iKodify.</b></p>
      <p>Where knowledge meets innovation.</p>
      <br/>
      <br/>
      <div className="logo"></div>

    </div>
  );
}

export default App;
